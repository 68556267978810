<template>
    <div style="height: 64px;">
        <div v-if="!scrollShow" :class="isActive !== 2 ? 'nav' : 'nav nav-white'">
            <img v-if="isActive !== 2" class="nav-logo" @click="goHome" src="@/assets/home/logo.png" />
            <img v-else class="nav-logo" @click="goHome" src="@/assets/home/nav-logo-white.png" />
            <div @mouseleave="mouseleaveHandle" :class="isActive !== 2 ? 'nav-list' : 'nav-list nav-list-whiter'">
                <div ref="slide" class="aside_slide"></div>
                <span v-for="(    item, index    ) in     navList    " :key="index" @mouseenter="mouseenterHandle(index)"
                    @click="toggle(item.name, index)" :ref="setItemRef">{{
                        item.title }}</span>
            </div>
            <div class="nav-login" v-if="!LoginShow">
                <span @click="register">注册</span>
                <span @click="login">登录</span>
            </div>
            <div :class="isActive !== 2 ? 'control' : 'control loginControl'" v-else>
                <div class="img"></div>
                <span @click="control">控制台</span>
            </div>
        </div>
        <div v-else class="nav">
            <img class="nav-logo" @click="goHome" src="@/assets/home/logo.png" />
            <div @mouseleave="mouseleaveHandle" class="nav-list">
                <div ref="slide" class="aside_slide"></div>
                <span v-for="(    item, index    ) in     navList    " :key="index" @mouseenter="mouseenterHandle(index)"
                    @click="toggle(item.name, index)" :ref="setItemRef">{{
                        item.title }}</span>
            </div>
            <div class="nav-login" v-if="!LoginShow">
                <span @click="register">注册</span>
                <span @click="login">登录</span>
            </div>
            <div class="control" v-else>
                <div class="img"></div>
                <span @click="control">控制台</span>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>

import {
    ref, watch, computed, reactive
} from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import ls from '@/utils/storage.js'
const router = useRouter()
const store = useStore()
let LoginShow = ref(false)
let navList = ref([
    {
        title: '首页',
        name: 'home'
    },
    {
        title: '产品服务',
        name: 'Products'
    },
    {
        title: '企业社会责任',
        name: 'socialResponsibility'
    }
])
import { onMounted } from 'vue'
//设置isActive 
let fn = async () => {
    if (router.currentRoute.value.name == "home") {
        store.commit('setMenu', 0)
    } else if (router.currentRoute.value.name == "Products") {
        store.commit('setMenu', 1)
    }
    else if (router.currentRoute.value.name == "socialResponsibility") {
        store.commit('setMenu', 2)
    }
}
let scrollShow = ref(false)
let scrollHandle = () => {
    if (itemRefs.length > 3) {
        itemRefs.splice(0, 3)
    }
    // 滚动条距文档顶部的距离
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 100) {
        scrollShow.value = true
    } else {
        scrollShow.value = false
    }
}
onMounted(() => {
    fn()
    const strCookie = document.cookie
    if (strCookie.indexOf('bhygx_tmp1') !== -1) {
        LoginShow.value = true
    } else {
        LoginShow.value = false
    }
    window.addEventListener("scroll", scrollHandle)
})

//logo返回首页
let goHome = () => {
    router.push({
        path: '/'
    })
    store.commit('setMenu', 0)
    if (router.currentRoute.value.name == "home") {
        location.reload()
    }
}
//登录按钮
let login = () => {
    window.location.href = import.meta.env.VITE_ROUTER_LOGIN_URL
}
let register = () => {
    window.location.href = import.meta.env.VITE_ROUTER_LOGIN_URL + "?web=true"
}
let control = () => {
    window.location.href = import.meta.env.VITE_ROUTER_HOME_URL
}
let isActive = ref(0)
//设置所有动态ref 赋值给itemRefs
const slide = ref()
let itemRefs = []
const setItemRef = (el: any) => {
    if (el) {
        itemRefs.push(el)
    }
}
//切换页面 并且 nav点击获取元素大小位置
let toggle = (path: string, index: number) => {
    isActive.value = index
    store.commit('setMenu', index)
    router.push({
        path: '/' + path
    })
    setSlide(index)
}
//鼠标移入
const mouseenterHandle = (index: number) => {
    setSlide(index)
}
//鼠标离开
const mouseleaveHandle = () => {
    slide.value.style.width = '0PX'
}
//切换导航栏滑动效果 index当前选中或者默认下标
const setSlide = (index: number) => {
    if (itemRefs.length > 3) {
        itemRefs.splice(0, 3)
    }
    slide.value.style.width = itemRefs[index].clientWidth + 'px'
    slide.value.style.left = itemRefs[index].offsetLeft + 'px'
    if (isActive.value !== 2) {
        slide.value.style.background = "#0049E3"
    } else {
        if (scrollShow.value) {
            slide.value.style.background = "#0049E3"
        } else {
            slide.value.style.background = "#fff"
        }
    }

}
//监听
const getMenu = computed(() => {
    //返回的是ref对象
    return store.state.activeMenu;
})
watch(getMenu, (newVal) => {
    store.commit('setMenu', newVal)
    isActive.value = ls.get('activeMenu')
}, { immediate: true, deep: true })
</script>
<style lang="scss" scoped>
.nav {
    width: 100%;
    height: 64px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 10px 0px rgba(16, 30, 57, 0.09);
    position: relative;
    z-index: 9;
    position: fixed;
    top: 0;

    .nav-logo {
        width: 138px;
        height: 38px;
        vertical-align: top;
        margin: 11px 0px 0 40px;
        cursor: pointer;
    }



    .nav-list {
        display: inline-block;
        margin: 21px 0;
        font-size: 16px;
        margin-left: 182px;
        position: relative;
        height: 43px;

        .aside_slide {
            width: 0PX;
            position: absolute;
            height: 3px;
            bottom: 0px;
            transition: all 0.2s;
        }

        span {
            margin-right: 50px;
            display: inline-block;

            &:hover {
                color: #0049E3;
                cursor: pointer;
            }
        }

        // .nav-active {
        //     color: #0049E3;
        //     padding-bottom: 19px;
        //     border-bottom: 3px solid #0049E3;
        //     cursor: pointer;
        // }
    }

    .nav-list-whiter {
        span {
            color: #fff;

            &:hover {
                color: #fff;
            }
        }
    }

    .nav-login {
        float: right;

        span {
            display: inline-block;
            font-size: 16px;
            width: 134px;
            height: 64px;
            text-align: center;
            line-height: 64px;
            cursor: pointer;
        }

        :first-child {
            &:hover {
                color: #0049e3;
            }
        }

        :last-child {
            background: #0049e3;
            color: #fff !important;

            &:active {
                background: #003FC4;
            }
        }
    }

    .control {
        float: right;
        width: 134px;
        height: 64px;
        text-align: center;
        cursor: pointer;

        .img {
            background: url("@/assets/home/nav-icon.png") no-repeat;
            background-size: 100% 100%;
            width: 16px;
            height: 16px;
            margin-right: 8px;
            margin-top: 24px;
            display: inline-block;
        }

        span {
            color: #343C58;
            display: inline-block;
            vertical-align: top;
            line-height: 64px;
            font-size: 16px;
        }

        &:hover {
            span {
                color: #0049E3;
            }

            .img {
                background: url("@/assets/home/nav-iconHover.png") no-repeat;
                background-size: 100% 100%;
                width: 16px;
                height: 16px;
            }

        }
    }

    .loginControl {
        span {
            color: #fff;
        }

        .img {
            background: url("@/assets/home/nav-icon-white.svg") no-repeat !important;
        }

        &:hover {
            .img {
                background: url("@/assets/home/nav-icon.svg") no-repeat !important;
            }

            span {
                color: #0049E3;
            }
        }
    }
}

.nav-white {
    background: rgba(255, 255, 255, 0.1);

    .nav-login {
        :first-child {
            color: #fff;

            &:hover {
                color: #0049E3 !important;
            }
        }
    }
}
</style>
