import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

// 2. 配置路由
const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: 'home',
        component: () => import("@/views/pages/home.vue"),
    },
    {
        path: "/Products",
        name: 'Products',
        component: () => import("@/views/pages/Products.vue"),
    },
    {
        path: '/socialResponsibility',
        name: 'socialResponsibility',
        component: () => import('@/views/pages/Social.vue')
    },
    {
        path: '/:pathMatch(.*)*',// 此写法解决动态路由页面刷新的 warning 警告
        redirect: '/',
        // component: () => import('@/views/404.vue')
    }
];
// 1.返回一个 router 实列，为函数，里面有配置项（对象） history
const router = createRouter({
    history: createWebHistory(),
    routes,
});

// 3导出路由   然后去 main.ts 注册 router.ts
export default router
