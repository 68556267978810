import { createStore } from 'vuex'
import ls from "@/utils/storage"
/**
 * 创建仓库和导出
 */
export default createStore({
    state: {
        activeMenu: 0
    },
    mutations: {
        setMenu(state, param) {
            state.activeMenu = param
            ls.set('activeMenu', param, 24 * 60 * 60 * 1000)
        }
    }
})