<template>
    <div>
        <div class="footer">
            <div class="footer-box">
                <img class="footer-logo" src="@/assets/home/logo-black.png" alt="">
                <div class="list-box">
                    <div class="list-flex">
                        <div class="list-item">
                            <div class="vertical">
                                <div class="vertical-item"></div>
                            </div>
                            <!-- <div class="item-box">
                                <p class="title">联系我们</p>
                                <p class="text">电话：157-1145-1802</p>
                                <p class="text">地址：北京市东城区荷华明城大厦A座18层1802</p>
                            </div>
                            <div class="item-box">
                                <p class="title">微信</p>
                                <img src="@/assets/home/wxCode.png" alt="">
                            </div> -->
                        </div>
                        <div class="list-item">
                            <div class="item-box jump">
                                <p class="title">网站导航</p>
                                <p class="text" @click="Jump('首页')">首页</p>
                                <p class="text" @click="Jump('产品服务')">产品服务</p>
                                <p class="text" @click="Jump('企业社会责任')">企业社会责任</p>
                                <!-- <p class="text" @click="Jump('关于我们')">关于我们</p> -->
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="item-box jump">
                                <p class="title">关于我们</p>
                                <p class="text" @click="Jump('了解白话劳动法')">了解白话劳动法</p>
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="vertical">
                                <div class="vertical-item"></div>
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="item-box">
                                <p class="title">联系我们</p>
                                <p class="text">
                                    <img class="phone" src="@/assets/home/phoneIcon.png" alt="">
                                    <span class="number">157-1145-1802</span>
                                </p>
                                <p class="text">
                                    <img class="mailIcon" src="@/assets/home/mailIcon.png" alt="">
                                    <span class="content" style="line-height: 1;">bhygxservice@163.com</span>
                                </p>
                                <p class="text">
                                    <img class="address" src="@/assets/home/addressIcon.png" alt="">
                                    <span class="content" style="line-height: 15px;">北京市东城区崇文门外大街8号哈德门广场东塔6层604</span>
                                </p>
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="item-box">
                                <p class="title">微信咨询</p>
                                <img src="@/assets/home/wxCode.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-title">
                <img style="width: 14px;height: 14px;" src="https://blog-assets.jiandaoyun.com/v157/index/record_icon.png"
                    alt="">
                <p style @click="handle">白话用工（北京）科技服务有限公司 版权所有 京ICP备19049955号</p>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ref } from 'vue'
const store = useStore()
const router = useRouter()
let pathName = ref()
let Jump = (type: string) => {
    pathName.value = router.currentRoute.value.name
    if (pathName.value == 'home') {
        if (type == "首页") {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } else if (type == "产品服务") {
            router.push({
                path: '/Products'
            })
            store.commit('setMenu', 1)
            window.scrollTo({
                top: 0,
                behavior: "instant"
            });
        } else if (type == '企业社会责任') {
            router.push({
                path: '/socialResponsibility'
            })
            store.commit('setMenu', 2)
            window.scrollTo({
                top: 0,
                behavior: "instant"
            });
        } else {
            window.open("https://www.baihualaodongfa.com");
        }
    } else if (pathName.value == "Products") {
        if (type == "首页") {
            router.push({
                path: '/'
            })
            store.commit('setMenu', 0)
            window.scrollTo({
                top: 0,
                behavior: "instant"
            });
        } else if (type == "产品服务") {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } else if (type == '企业社会责任') {
            router.push({
                path: '/socialResponsibility'
            })
            store.commit('setMenu', 2)
            window.scrollTo({
                top: 0,
                behavior: "instant"
            });
        }
        else {
            window.open("https://www.baihualaodongfa.com");
        }
    } else if (pathName.value == "socialResponsibility") {
        if (type == '首页') {
            router.push({
                path: '/'
            })
            store.commit('setMenu', 0)
            window.scrollTo({
                top: 0,
                behavior: "instant"
            });
        } else if (type == '产品服务') {
            router.push({
                path: '/Products'
            })
            store.commit('setMenu', 1)
            window.scrollTo({
                top: 0,
                behavior: "instant"
            });
        } else if (type == '企业社会责任') {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } else {
            window.open("https://www.baihualaodongfa.com");
        }
    }
}
let handle = () => {
    window.open('https://beian.miit.gov.cn')
}
</script>
<style lang="scss" scoped>
.footer {
    background-color: #151B26;
    position: relative;
    height: 306px;

    .footer-box {
        width: 1200px;
        margin: auto;
        padding-top: 41px;
        border-bottom: 1px solid rgba(151, 151, 151, 0.23);

        .footer-logo {
            width: 156px;
            height: 39px;
        }

        .list-box {
            display: inline-block;
            vertical-align: top;
            width: 84%;
            padding-left: 46px;

            .list-flex {
                vertical-align: top;

                .list-item {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 70px;

                    &:last-child {
                        margin-right: 0px;
                    }

                    .vertical {
                        width: 1px;
                        height: 158px;
                        background-color: rgba(255, 255, 255, 0.13);
                        // margin-right: 65px;

                        .vertical-item {
                            width: 2px;
                            height: 38px;
                            background-color: #fff;
                        }
                    }

                    .item-box {
                        margin-bottom: 23px;

                        &.jump {
                            .text {
                                &:hover {
                                    color: #fff;
                                    cursor: pointer;
                                }
                            }
                        }

                        .title {
                            font-size: 14px;
                            color: #fff;
                            line-height: 20px;
                            margin-bottom: 23px;
                        }

                        .text {
                            font-size: 12px;
                            color: #BABBBE;
                            margin-bottom: 20px;

                            .phone {
                                width: 42px;
                                height: 42px;
                            }

                            .number {
                                font-family: "DIN-Condensed-Bold-2";
                                font-size: 20px;
                                color: #fff;
                                vertical-align: top;
                                display: inline-block;
                                margin-left: 9px;
                                margin-top: 7px;
                                letter-spacing: 2px;
                            }

                            .address {
                                width: 14px;
                                height: 15px;
                                margin-left: 8px;
                            }

                            .content {
                                font-size: 12px;
                                color: #878787;
                                margin-left: 15px;
                                vertical-align: top;
                                display: inline-block;
                            }

                            .mailIcon {
                                width: 17px;
                                height: 12px;
                                margin-left: 7px;
                            }
                        }

                        img {
                            width: 118px;
                            height: 118px;
                        }
                    }
                }
            }
        }

    }
}

.footer-title {
    color: #BABBBE;
    font-size: 12px;
    text-align: center;
    position: absolute;
    bottom: 36px;
    left: 50%;
    transform: translate(-50%, 0);

    img {
        width: 14px;
        height: 14px;
    }

    p {
        display: inline-block;
        vertical-align: top;
        margin-left: 5px;
    }
}
</style>