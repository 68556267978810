import { createApp } from 'vue'
import router from '@/router' //  router导入
import App from './App.vue'
import './permission'
import '@/common/reset.scss'
import "@/assets/font/font.css";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css';
// createApp(App).use(router).use(store).use(VueAwesomeSwiper).use(ElementPlus).mount('#app')
const vueApp = createApp(App)
vueApp.use(router).use(store).use(VueAwesomeSwiper).use(ElementPlus)

router.isReady().then(() => vueApp.mount('#app'))